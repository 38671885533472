/*eslint-disable*/
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import styled from 'styled-components';

import Select from 'react-select';
import ProxiesAPI from '../../../api/ProxiesAPI';

export default function LocationsSelector(props) {
  const { pingers_locations = [], onChange = (x) => {} } = props;
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    ProxiesAPI.getLocations().then((arr) => {
      setLocations(arr);
    });
  }, []);
  if (locations.length == 0) {
    return <div>загрузка...</div>;
  }

  let options = locations.map((xx) => ({
    value: xx.tag,
    label: `${xx.city} (${xx.country})`,
  }));

  return (
    <Wrapper>
      <Select
        isMulti={true}
        options={options}
        value={
          options.filter((x) => pingers_locations.indexOf(x.value) > -1)[0]
        }
        onChange={(x) => {
          if (x == undefined) {
            onChange([]);
          } else {
            console.log('x = ', x);
            onChange(x.map((xx) => xx.value));
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
