import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import moment from 'moment';
import uuid from 'uuid';
import { Map, Set } from 'immutable';
import styled from 'styled-components';
import { Code } from 'react-content-loader';
import Sidebar from 'arui-feather/sidebar';
import Spin from 'arui-feather/spin';
import axios from 'axios';

export default function TelegramInfoPanel(props) {
  const {
    currentUser,
    loading,
    proxies = [],
  } = useMappedState(
    useCallback(
      (state) => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading || state.proxies.loading,
        proxies: state.proxies.proxiesMap.toArray(),
      }),
      [],
    ),
  );

  console.log('TelegramInfoPanel: currentUser = ', currentUser);
  if (currentUser == undefined) {
    return null;
  }
  const { telegramChannelsIds = [] } = currentUser;
  const hasTelegram = telegramChannelsIds.length > 0;

  return (
    <Wrapper>
      {hasTelegram == false ? null : <div>Telegram bot is connected!</div>}

      {hasTelegram == true ? null : (
        <div>
          <p>
            Please connect{' '}
            <a
              href="https://t.me/iproxy_tools_bot"
              target="_blank"
              rel="noreferrer"
            >
              Telegram bot
            </a>
            .
          </p>
          <p>Type command "/login EMAIL PASSWORD"</p>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
