import ls from 'local-storage';
import ru from './i18n/ru.json';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import cn from './i18n/cn.json';
import de from './i18n/de.json';

export const translate = (key) => {
  const lang = ls('lang') || 'ru';
  let langData = ru; // default

  if (lang === 'ru') {
    langData = ru;
  }
  if (lang === 'en') {
    langData = en;
  }
  if (lang === 'fr') {
    langData = fr;
  }
  if (lang === 'cn') {
    langData = cn;
  }
  if (lang === 'de') {
    langData = de;
  }
  return langData[key];
};

export const printAllEnPhrases = () => {
  const keys = Object.keys(en);
  const vals = keys.map((x) => en[x]);
  const s = vals.join('\n');
  console.log(s);
};
