import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import moment from 'moment';
import uuid from 'uuid';
import { Map, Set } from 'immutable';
import styled from 'styled-components';
import { Code } from 'react-content-loader';
import Sidebar from 'arui-feather/sidebar';
import Spin from 'arui-feather/spin';
import axios from 'axios';
import ReactHelper from '../../../helpers/ReactHelper';
import CacheAPI from '../../../api/CacheAPI';
import TodoViewCol from './TodoViewCol';

export default function TodoCol(props) {
  const { status = 'new', isPigsMaster = false } = props;

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    CacheAPI.getTasksByStatus(status).then((x) => {
      setItems(x || []);
      setLoading(false);
    });
  }, []);

  ReactHelper.useInterval(() => {
    CacheAPI.getTasksByStatus(status).then((x) => {
      const sd = (x || []).map((xx) => xx.id).join('_');
      const sd0 = (items || []).map((xx) => xx.id).join('_');
      if (sd0 != sd) {
        setItems(x || []);
      }
    });
  }, 3 * 1000);

  return (
    <Wrapper>
      <TodoViewCol items={items} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
`;
