import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import moment from 'moment';
import uuid from 'uuid';
import { Map, Set } from 'immutable';
import styled from 'styled-components';
import { Code } from 'react-content-loader';
import Sidebar from 'arui-feather/sidebar';
import Spin from 'arui-feather/spin';
import axios from 'axios';
import CommonHelper from '../../helpers/CommonHelper';
// import ep_logo_wide_img from "./images/ep_new_year_logo.svg";
import ep_logo_wide_img from './images/iproxy_tools.svg';

export default function LogoDiv(props) {
  const [updT, setUpdT] = useState(0);

  return (
    <LogoPlaceholder
      key={`xx_${updT}`}
      onClick={() => {
        CommonHelper.linkTo('/');
      }}
    >
      <Logo src={ep_logo_wide_img} />
    </LogoPlaceholder>
  );
}

const topHeight = 70;
const leftWidth = 300;

const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

const LogoPlaceholder = styled.div`
  width: ${leftWidth}px;
  height: ${topHeight}px;
  cursor: pointer;
  box-sizing: border-box;

  :hover {
    opacity: 0.8;
  }
`;
