import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import styled from 'styled-components';
import UserTemplate from '../templates/UserTemplate';
import ProxiesPanel from '../proxies/panels/ProxiesPanel';
import PigsTemplate from '../templates/PigsTemplate';
import TodoPanel from '../todos/dash/TodoPanel';
import VideoComp from '../todos/tools/VideoComp';
import CommonHelper from '../../helpers/CommonHelper';

const ITEMS = ['aggression', 'dead_pig', 'queue', 'feeder_overload'];

export default function RoomsApp(props) {
  return (
    <PigsTemplate active="index">
      <Wrapper>
        <InnerPlaceholder>
          {ITEMS.map((x, i) => (
            <Item
              key={i}
              onClick={() => {
                CommonHelper.linkTo(`/video/${x}`);
              }}
            >
              <VideoComp videoCode={x} />
            </Item>
          ))}
        </InnerPlaceholder>
      </Wrapper>
    </PigsTemplate>
  );
}

const Wrapper = styled.div`
  padding: 10px;
  width: 1200px;
  margin: 0 auto;
`;

const InnerPlaceholder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Item = styled.div`
  padding: 10px;
`;
