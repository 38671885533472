import * as types from '../ActionTypes.js';
import ProxiesAPI from '../../api/ProxiesAPI.js';

const createProxy_ = () => ({
  type: types.CREATE_PROXY,
});
const createProxySuccess = (proxy) => ({
  type: types.CREATE_PROXY_SUCCESS,
  proxy,
});
const createProxyFailed = (error) => ({
  type: types.CREATE_PROXY_FAIL,
  error,
});

// thunk
export function createProxy(data) {
  return (dispatch, getState) => {
    dispatch(createProxy_());
    return ProxiesAPI.createProxy(data).then(
      (proxy) => dispatch(createProxySuccess(proxy)),
      (error) => dispatch(createProxyFailed(error)),
    );
  };
}

// eca
const updateProxy_ = () => ({
  type: types.UPDATE_PROXY,
});
const updateProxySuccess = (proxy) => ({
  type: types.UPDATE_PROXY_SUCCESS,
  proxy,
});
const updateProxyFailed = (error) => ({
  type: types.UPDATE_PROXY_FAIL,
  error,
});

// thunk
export function updateProxy(data) {
  return (dispatch, getState) => {
    dispatch(updateProxy_());
    return ProxiesAPI.updateProxy(data).then(
      (proxy) => dispatch(updateProxySuccess(proxy)),
      (error) => dispatch(updateProxyFailed(error)),
    );
  };
}

const loadProxies_ = () => ({
  type: types.LOAD_PROXIES,
});
const loadProxiesSuccess = (proxies) => ({
  type: types.LOAD_PROXIES_SUCCESS,
  proxies,
});
const loadProxiesFailed = (error) => ({
  type: types.LOAD_PROXIES_FAIL,
  error,
});

// thunk
export function loadProxies() {
  return (dispatch, getState) => {
    dispatch(loadProxies_());
    return ProxiesAPI.getAllProxies().then(
      (proxies) => dispatch(loadProxiesSuccess(proxies)),
      (error) => dispatch(loadProxiesFailed(error)),
    );
  };
}

const deleteProxy_ = () => ({
  type: types.DELETE_PROXY,
});
const deleteProxySuccess = (id) => ({
  type: types.DELETE_PROXY_SUCCESS,
  id,
});
const deleteProxyFailed = (error) => ({
  type: types.DELETE_PROXY_FAIL,
  error,
});

// thunk
export function deleteProxy(id) {
  return (dispatch, getState) => {
    dispatch(deleteProxy_());
    return ProxiesAPI.deleteProxy(id).then(
      () => dispatch(deleteProxySuccess(id)),
      (error) => dispatch(deleteProxyFailed(error)),
    );
  };
}
