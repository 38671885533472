import { useLayoutEffect, useRef, useState } from 'react';
import moment from 'moment';
import { translate } from '../utils/Translate';

export const RU_MONTHS_ARRAY = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export function useDimensions() {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({});
  useLayoutEffect(() => {
    try {
      setDimensions(ref.current.getBoundingClientRect().toJSON());
    } catch (exc) {}
  }, [ref.current]);
  return [ref, dimensions];
}

const CommonHelper = {
  linkTo(patientUrl, inNewTab = false) {
    const url = `${window.location.origin + window.location.pathname}#${patientUrl}`;
    if (inNewTab == false) {
      window.location.href = url;
    } else {
      const win = window.open(url, '_blank');
      win.focus();
    }
  },

  getRuMonthByTimestamp(t) {
    const m = +moment(t).format('M') - 1;
    return RU_MONTHS_ARRAY[+m];
  },

  getCurrentUserRole(state) {
    if (state == undefined) {
      return false;
    }
    const { currentUserId, usersMap } = state.users;
    const currentUser = usersMap.get(currentUserId);
    if (currentUser == undefined) {
      return undefined;
    }
    return currentUser.userRole;
  },

  getRuNameOfRole(role) {
    const map = {
      admin: 'Админ',
      teacher: translate('teacher'),
      student: translate('student'),
    };
    return map[role];
  },

  getUserName(user) {
    if (user == undefined) {
      return 'Guest';
    }
    const { firstName, lastName, name } = user;
    const fn = firstName == undefined || firstName == '' ? '' : firstName;
    const ln = lastName == undefined || lastName == '' ? '' : lastName;
    if (fn == '' && ln == '') {
      return user.email;
    }
    return `${fn} ${ln}`;
  },

  convertSecondstoTime(given_seconds) {
    const dateObj = new Date(given_seconds * 1000);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getSeconds();

    if (window.isNaN(seconds) == true) {
      return '';
    }

    const timeString = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return timeString;
  },

  pauseAllAudioPlayersExceptForOne(url) {
    try {
      const arr = document.getElementsByTagName('audio');
      for (const a of arr) {
        try {
          if (a.src == url) {
            continue;
          }
          a.pause();
        } catch (ee2) {}
      }
    } catch (exc) {}
  },
};

export default CommonHelper;
