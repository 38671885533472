import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';

import DevApp from './DevApp';

import * as usersActions from '../../redux/actions/UsersActions';
import * as proxiesActions from '../../redux/actions/ProxiesActions';
import LoginApp from './LoginApp';
import CheckerApp from './CheckerApp';
import SettingsApp from './SettingsApp';

import IndexApp from './IndexApp';
import VideoApp from './VideoApp';
import PigsMasterApp from './PigsMasterApp';
import TranslationsApp from './TranslationsApp';
import RoomsApp from './RoomsApp';
import ContainersApp from './ContainersApp';
import OneContainerApp from './OneContainerApp';
import StaffApp from './StaffApp';
import StatsApp from './StatsApp';

const usersRoute = (
  <Switch>
    <Route exact path="/dev" component={DevApp} />
    <Route exact path="/checker" component={CheckerApp} />
    <Route exact path="/settings" component={SettingsApp} />
    <Route exact path="/video/:code" component={VideoApp} />

    <Route exact path="/rooms" component={RoomsApp} />
    <Route exact path="/staff" component={StaffApp} />
    <Route exact path="/stats" component={StatsApp} />
    <Route exact path="/containers" component={ContainersApp} />
    <Route exact path="/containers/:id" component={OneContainerApp} />

    <Route component={IndexApp} />
  </Switch>
);

const pigsMasterRoute = (
  <Switch>
    <Route exact path="/dev" component={DevApp} />
    {/* <Route exact path='/checker' component={CheckerApp}/> */}
    {/* <Route exact path='/settings' component={SettingsApp}/> */}
    {/* <Route exact path='/video/:code' component={VideoApp}/> */}

    <Route component={PigsMasterApp} />
  </Switch>
);

function RouterApp(props) {
  const mapState = useCallback(
    (state) => ({
      initialized: state.users.initialized,
      currentUser:
        state.users.currentUserId == undefined
          ? null
          : state.users.usersMap.get(state.users.currentUserId),
    }),
    [],
  );
  const { initialized, currentUser } = useMappedState(mapState);
  const dispatch = useDispatch();

  console.log(
    'RouterApp: initialized, currentUser = ',
    initialized,
    currentUser,
  );

  useEffect(() => {
    dispatch(usersActions.initializeAuthorization())
      .then((pld) => {
        if (pld.user != undefined) {
          window.currentUserId = pld.user.id;
          console.log('==>>> pld = ', pld);
          dispatch(proxiesActions.loadProxies());
        }
      })
      .catch((exc) => {
        console.log('!!! => exc = ', exc);
      });
  }, []);

  let userRoute = usersRoute;

  if (initialized == false) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        загрузка...
      </div>
    );
  }

  if (currentUser == undefined) {
    return <LoginApp />;
  }
  const isPigsMaster = currentUser?.userRole == 'pigs-master';
  if (isPigsMaster == true) {
    userRoute = pigsMasterRoute;
  }

  console.log('current user = ', currentUser);

  return (
    <HashRouter>
      <Wrapper>{userRoute}</Wrapper>
    </HashRouter>
  );
}

const Wrapper = styled.div`
  font-family: Montserrat;
  //font-family: "Avenir Next Cyr";
`;

export default RouterApp;
