import { Map } from 'immutable';
import * as types from '../ActionTypes.js';

const initialState = {
  loading: false,
  proxiesMap: Map(),

  selectedTreadmillId: undefined,
  error: undefined,
};

const startLoading = (state, action) => ({
  ...state,
  loading: true,
  error: undefined,
});

const stopLoading = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const ProxiesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CREATE_PROXY:
    case types.UPDATE_PROXY:
    case types.DELETE_PROXY:
    case types.LOAD_PROXIES:
      return startLoading(state, action);

    case types.CREATE_PROXY_FAIL:
    case types.UPDATE_PROXY_FAIL:
    case types.DELETE_PROXY_FAIL:
    case types.LOAD_PROXIES_FAIL:
      return stopLoading(state, action);

    case types.CREATE_PROXY_SUCCESS:
    case types.UPDATE_PROXY_SUCCESS:
      return {
        ...state,
        loading: false,
        proxiesMap: state.proxiesMap.set(action.proxy.id, action.proxy),
      };

    case types.DELETE_PROXY_SUCCESS:
      return {
        ...state,
        loading: false,
        proxiesMap: state.proxiesMap.delete(action.id),
      };

    case types.LOAD_PROXIES_SUCCESS:
      return {
        ...state,
        loading: false,
        proxiesMap: state.proxiesMap.merge(
          action.proxies.reduce((mp, ex) => mp.set(ex.id, ex), Map()),
        ),
      };

    default:
      return state;
  }
};

export default ProxiesReducer;
