import * as types from '../ActionTypes';
import UsersAPI from '../../api/UsersAPI';

// LOGIN
const startLoggingIn = () => ({
  type: types.LOGIN,
});
const onLoggedIn = (user) => ({
  type: types.LOGIN_SUCCESS,
  user,
});
const onLoginFailed = (error) => ({
  type: types.LOGIN_FAIL,
  error,
});

// thunk
export function logIn(data) {
  console.log('-> logIn: data = ', data);
  return (dispatch, getState) => {
    dispatch(startLoggingIn());
    return UsersAPI.login(data.email, data.password).then(
      (user) => dispatch(onLoggedIn(user)),
      (error) => dispatch(onLoginFailed(error)),
    );
  };
}

export function socialLogIn(data) {
  console.log('distpatched logIn: data = ', data);
  return (dispatch, getState) => {
    dispatch(startLoggingIn());
    return UsersAPI.parselessSocialLogin(
      data.email,
      data.password,
      data.social,
    ).then(
      (user) => dispatch(onLoggedIn(user)),
      (error) => dispatch(onLoginFailed(error)),
    );
  };
}

// SIGNUP
const startSigningUp = () => ({
  type: types.SIGNUP,
});
const onSignedUp = (user) => ({
  type: types.SIGNUP_SUCCESS,
  user,
});
const onSignUpFail = (error) => ({
  type: types.SIGNUP_FAIL,
  error,
});

// thunk
export function signUp(data) {
  return (dispatch, getState) => {
    dispatch(startSigningUp());
    return UsersAPI.parselessSignup(data).then(
      (user) => dispatch(onSignedUp(user)),
      (error) => dispatch(onSignUpFail(error)),
    );
  };
}

// LOGOUT
const startLoggingOut = () => {
  console.log('startLoggingOut occured');
  return {
    type: types.LOGOUT,
  };
};
const onLogoutFail = () => ({
  type: types.LOGOUT_FAIL,
});
const onLoggedOut = () => ({
  type: types.LOGOUT_SUCCESS,
});

// thunk
export function logOut() {
  return (dispatch, getState) => {
    const usersState = getState().users;
    console.log('usersState = ', usersState);
    if (usersState.currentUserId == undefined) {
      return Promise.resolve();
    }
    dispatch(startLoggingOut());
    return UsersAPI.logout().then(
      () => dispatch(onLoggedOut()),
      () => dispatch(onLogoutFail()),
    );
  };
}

// AUTH_INIT
const startAuthInit = () => ({
  type: types.INITIALIZE_AUTH,
});
const authInitFailed = (err) => ({
  type: types.INITIALIZE_AUTH_FAIL,
  error: err,
});
const authInitSuccess = (user) => ({
  type: types.INITIALIZE_AUTH_SUCCESS,
  user,
});

// thunk
export function initializeAuthorization() {
  return (dispatch, getState) => {
    dispatch(startAuthInit());
    return UsersAPI.getMe().then(
      (d) => dispatch(authInitSuccess(d)),
      (err) => dispatch(authInitFailed()),
    );
  };
}

export function getCurrentUserInfo() {
  return (dispatch, getState) =>
    UsersAPI.getMe().then(
      (d) => dispatch(authInitSuccess(d)),
      (err) => dispatch(authInitFailed()),
    );
}

// USERS
const loadUsers_ = () => ({
  type: types.LOAD_USERS,
});

const loadUsersFail = (error) => ({
  type: types.LOAD_USERS_FAIL,
  error,
});

const loadUsersSuccess = (users, links) => ({
  type: types.LOAD_USERS_SUCCESS,
  users,
});

export function loadUserById(id) {
  return (dispatch, getState) => {
    dispatch(loadUsers_());
    return UsersAPI.getUser(id).then(
      (u) => dispatch(loadUsersSuccess([u])),
      (error) => dispatch(loadUsersFail(error)),
    );
  };
}

export function loadUsersByIds(ids) {
  return (dispatch, getState) => {
    dispatch(loadUsers_());
    return UsersAPI.getUsersByIds(ids).then(
      (users) => dispatch(loadUsersSuccess(users)),
      (error) => dispatch(loadUsersFail(error)),
    );
  };
}

// update user

const updateUser_ = () => ({
  type: types.UPDATE_USER,
});

const updateUserFail = (err) => ({
  type: types.UPDATE_USER_FAIL,
  error: err,
});

export const updateUserSuccess = (user) => ({
  type: types.UPDATE_USER_SUCCESS,
  user,
});

// thunk
export function updateUser(data) {
  return (dispatch, getState) => {
    // let {currentUserId, usersMap} = getState().users;
    // data.id = currentUserId;
    dispatch(updateUser_());
    return UsersAPI.updateUser(data).then(
      (user) => dispatch(updateUserSuccess(user)),
      (err) => dispatch(updateUserFail(err)),
    );
  };
}

// load all users
export function loadAllUsers() {
  return (dispatch, getState) => {
    dispatch(loadUsers_());
    return UsersAPI.getAllUsers().then(
      (users) => dispatch(loadUsersSuccess(users)),
      (err) => dispatch(loadUsersFail(err)),
    );
  };
}

export function loadAllTeachers() {
  return (dispatch, getState) => {
    dispatch(loadUsers_());
    return UsersAPI.getAllTeachers().then(
      (users) => dispatch(loadUsersSuccess(users)),
      (err) => dispatch(loadUsersFail(err)),
    );
  };
}

const createUser_ = () => ({
  type: types.CREATE_USER,
});
const createUserSuccess = (user) => ({
  type: types.CREATE_USER_SUCCESS,
  user,
});
const createUserFailed = (error) => ({
  type: types.CREATE_USER_FAIL,
  error,
});

// thunk
export function createUser(data) {
  return (dispatch, getState) => {
    dispatch(createUser_());
    return UsersAPI.createUser(data).then(
      (user) => dispatch(createUserSuccess(user)),
      (error) => dispatch(createUserFailed(error)),
    );
  };
}

const deleteUser_ = () => ({
  type: types.DELETE_USER,
});
const deleteUserSuccess = (id) => ({
  type: types.DELETE_USER_SUCCESS,
  id,
});
const deleteUserFailed = (error) => ({
  type: types.DELETE_USER_FAIL,
  error,
});

// thunk
export function deleteUser(id) {
  return (dispatch, getState) => {
    dispatch(deleteUser_());
    return UsersAPI.deleteUser(id).then(
      (data) => dispatch(deleteUserSuccess(id)),
      (error) => dispatch(deleteUserFailed(error)),
    );
  };
}
