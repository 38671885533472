import { combineReducers } from 'redux';

import UsersReducer from './UsersReducer.js';
import LanguageReducer from './LanguageReducer.js';

import ProxiesReducer from './ProxiesReducer';

export const reducer = combineReducers({
  proxies: ProxiesReducer,
  language: LanguageReducer,
  users: UsersReducer,
});
