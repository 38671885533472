import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import styled from 'styled-components';
import UserTemplate from '../templates/UserTemplate';
import ProxiesPanel from '../proxies/panels/ProxiesPanel';
import PigsTemplate from '../templates/PigsTemplate';
import TodoPanel from '../todos/dash/TodoPanel';
import VideoComp from '../todos/tools/VideoComp';

import cont_img from '../../assets/img/f_stats.svg';
import CommonHelper from '../../helpers/CommonHelper';

export default function StatsApp(props) {
  return (
    <PigsTemplate active="staff">
      <Wrapper>
        <Img
          src={cont_img}
          onClick={() => {
            CommonHelper.linkTo('/');
          }}
        />
      </Wrapper>
    </PigsTemplate>
  );
}

const Wrapper = styled.div`
  padding: 10px;
  width: 1200px;
  margin: 0 auto;
`;

const Heading = styled.h2``;

const Img = styled.img`
  width: 100%;
`;

const InnerPlaceholder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Item = styled.div`
  padding: 10px;
`;
