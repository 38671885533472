import axios from 'axios';
import ls from 'local-storage';
import { API_ENDPOINT } from '../constants/config';

const UsersAPI = {
  async getMe() {
    try {
      console.log('UsersAPI: getMe  ');
      const pld = (
        await axios.get(`${API_ENDPOINT}/users/me`, {
          headers: {
            Authorization: ls('auth_token'),
          },
        })
      ).data;
      console.log('got pld = ', pld);
      return pld;
    } catch (exc) {}
    return undefined;
  },

  async login(email, password) {
    console.log('login: email, password = ', email, password);
    try {
      const pld = (
        await axios.post(`${API_ENDPOINT}/users/login`, {
          email,
          password,
        })
      ).data;
      console.log('-> pld = ', pld);
      if (pld.error != undefined) {
        throw 'invalid login or password';
      }
      ls('auth_token', pld.token);
      const me = await this.getMe();
      console.log('got me = ', me);
      return me;
    } catch (e) {
      console.log('exc = ', e);
    }
    throw 'Неверный логин или пароль';
  },

  async logout() {
    ls.remove('auth_token');
  },

  async getUser(userId) {
    // let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}`)).data;
    const pld = (
      await axios.get(`${API_ENDPOINT}/users/by_ids`, {
        params: {
          usersIds: [userId],
        },
      })
    ).data;
    console.log('pld = ', pld);
    return pld[0];
  },

  async getUsersByIds(ids = []) {
    const pld = (
      await axios.get(`${API_ENDPOINT}/users/by_ids`, {
        params: {
          usersIds: ids,
        },
      })
    ).data;
    return pld;
  },

  async updateUser(d) {
    const pld = (
      await axios.post(`${API_ENDPOINT}/user/update`, {
        ...d,
      })
    ).data;
    return pld;
  },

  async forceSetPassword(d) {
    const pld = (
      await axios.post(`${API_ENDPOINT}/v3/reset-force-pwd`, {
        ...d,
      })
    ).data;
    return pld;
  },
};

export default UsersAPI;
