import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin';

export default function IraLoginForm(props) {
  const {
    canSignUp = true,
    onForgotPasswordClick = () => {},
    onSignupClick = () => {},
    loading = false,
    onSubmit = (d) => {},
    error,
  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Wrapper>
      <Heading>Вход</Heading>

      <SubHeading>Войдите, чтобы продолжить работу</SubHeading>

      <Field>
        <Label>Email</Label>
        <Input
          placeholder="Введите вашу почту"
          value={email}
          onChange={(evt) => {
            setEmail(evt.target.value);
          }}
        />
      </Field>

      <Field>
        <Label>
          <span>Пароль</span>
          <ForgotSpan
            onClick={() => {
              onForgotPasswordClick();
            }}
          >
            Забыли пароль?
          </ForgotSpan>
        </Label>
        <Input
          type="password"
          placeholder=""
          value={password}
          onChange={(evt) => {
            setPassword(evt.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onSubmit({
                email: email.toLowerCase().replace(/ /g, ''),
                password,
              });
            }
          }}
        />
      </Field>

      {error == undefined ? null : (
        <div style={{ padding: 5, textAlign: 'center', color: 'red' }}>
          {error}
        </div>
      )}

      <ButtonPlaceholder>
        <LoginButton
          onClick={() => {
            onSubmit({
              email: email.toLowerCase().replace(/ /g, ''),
              password,
            });
          }}
        >
          <Spin visible={loading} theme="alfa-on-color" />
          {loading == true ? null : <span>ВОЙТИ</span>}
        </LoginButton>
      </ButtonPlaceholder>

      {canSignUp == false ? null : (
        <VeryBottomPlaceholder>
          <BotSpan>У вас нет аккаунта?</BotSpan>
          <SignupSpan
            onClick={() => {
              onSignupClick();
            }}
          >
            <span>Зарегистрироваться</span>
          </SignupSpan>
        </VeryBottomPlaceholder>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  width: 430px;
  background: white;
  box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
  border-radius: 4px;
  padding: 30px;
`;

const Heading = styled.div`
  text-align: center;
  font-weight: bold;
  color: #202224;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.114286px;
  margin-bottom: 20px;
`;

const SubHeading = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.0642857px;
  color: #202224;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 35px;
`;

const Field = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #8593a8;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

const Input = styled.input`
  font-size: 14px;
  border: 1px solid #8593a8;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;
  width: 100%;
  height: 44px;
  line-height: 42px;
  color: black;
  outline: none;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: rgba(31, 32, 65, 0.25);
  }
`;

const ButtonPlaceholder = styled.div`
  margin-top: 15px;
`;

const LoginButton = styled.div`
  width: 100%;
  cursor: pointer;
  background: #085bff;
  border-radius: 1000px;
  box-sizing: border-box;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;

  :hover {
    opacity: 0.9;
  }
`;

const ForgotSpan = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #085bff;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

const VeryBottomPlaceholder = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const BotSpan = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #202224;
  mix-blend-mode: normal;
  opacity: 0.65;
  margin-right: 10px;
`;

const SignupSpan = styled.span`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  text-decoration-line: underline;
  color: #ff624f;
  cursor: pointer;
  font-weight: 600;

  :hover {
    opacity: 0.9;
  }
`;
