import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import moment from 'moment';
import uuid from 'uuid';
import { Map, Set } from 'immutable';
import { exportFile } from 'fs-browsers';
import styled from 'styled-components';
import ls from 'local-storage';
import Spin from 'arui-feather/spin';
import { toast } from 'react-hot-toast';
import { Textarea } from '../../ira/ui/Inputs';
import { GreenButton } from '../../ira/ui/Buttons';
import ProxiesAPI from '../../../api/ProxiesAPI';

function getProxies(txt) {
  if (txt == undefined) {
    return [];
  }
  const rows = txt.split('\n');
  const arr = [];
  for (const i in rows) {
    let s = rows[i];
    s = s.replace(/ /g, '');
    if (s.indexOf('://') == -1) {
      continue;
    }
    const type = s.split('://')[0];
    if (type != 'http' && type != 'socks5') {
      continue;
    }
    s = s.split('://')[1];
    let logPass = '';
    let login = '';
    let password = '';
    let address = '';
    let port = '';
    let addressPart = '';
    if (s.indexOf('@') == -1) {
      addressPart = s;
    } else {
      addressPart = s.split('@')[1];
      logPass = s.split('@')[0];
    }
    if (addressPart != '') {
      if (addressPart.indexOf(':') == -1) {
        continue;
      }
      address = addressPart.split(':')[0];
      port = addressPart.split(':')[1];
    }
    if (logPass != '') {
      if (logPass.indexOf(':') == -1) {
        login = logPass;
      } else {
        login = logPass.split(':')[0];
        password = logPass.split(':')[1];
      }
    }
    if (address == '' || address.indexOf('.') == -1) {
      continue;
    }
    if (port == '' || window.isNaN(port)) {
      continue;
    }
    arr.push({
      type,
      login,
      password,
      address,
      port: +port,
    });
  }
  return arr;
}

function exportProxies(proxies, name = 'proxies.txt') {
  const s = proxies
    .map((x) => `${x.type}://${x.login}:${x.password}@${x.address}:${x.port}`)
    .join('\n');
  exportFile(s, { fileName: name });
}

export default function CheckerTool(props) {
  const [text, setText] = useState(ls('checker_text'));
  const [checking, setChecking] = useState(false);

  const [checked, setChecked] = useState(false);
  const [checkerResult, setCheckerResult] = useState([]);

  useEffect(() => {
    ls('checker_text', text);
  }, [text]);

  console.log('render: checkerResult = ', checkerResult);

  let proxies = getProxies(text);
  const resultMap = checkerResult.reduce(
    (mp, x) => ({ ...mp, [x.proxy]: x }),
    {},
  );
  console.log('render: resultMap = ', resultMap);
  proxies = proxies
    .map((a) => ({
      ...a,
      pingerResult: resultMap[`${a.type}://${a.address}:${a.port}`],
    }))
    .map((a) => ({
      ...a,
      online: a?.pingerResult?.ping_metrics[0]?.result == true,
    }));

  console.log('proxies = ', proxies);

  return (
    <Wrapper>
      <InnerWrapper>
        <Left>
          <Textarea
            style={{
              lineHeight: '24px',
              fontSize: 16,
              minHeight: '20em',
              height: '100%',
            }}
            placeholder="Paste here list of proxies separated by Enter"
            value={text}
            onChange={(evt) => {
              setText(evt.target.value);
            }}
          />
        </Left>
        {proxies.length == 0 ? null : (
          <Right>
            <ListPlaceholder>
              {proxies.map((a, i) => {
                const rVal = a.pingerResult;
                const error = rVal?.ping_metrics[0]?.error;
                const isOnline = rVal?.ping_metrics[0]?.result;
                return (
                  <ListItem
                    checked={checked}
                    key={`${a.login}_${i}`}
                    online={isOnline}
                    onClick={() => {}}
                  >
                    <div>{a.name}</div>
                    <InfosPlaceholder>
                      {[
                        {
                          label: 'Type',
                          value: 'type',
                        },
                        {
                          label: 'Address',
                          value: 'address',
                        },
                        {
                          label: 'Port',
                          value: 'port',
                        },
                        {
                          label: 'Login',
                          value: 'login',
                        },
                        {
                          label: 'Password',
                          value: 'password',
                        },
                      ].map((b, j) => (
                        <InfoItem key={j}>
                          <InfoLabel>{b.label}</InfoLabel>
                          <InfoValue>{a[b.value]}</InfoValue>
                        </InfoItem>
                      ))}
                    </InfosPlaceholder>
                    {error == undefined || error == '' ? null : (
                      <div
                        style={{
                          fontSize: 12,
                          opacity: 0.5,
                          fontStyle: 'italic',
                        }}
                      >
                        {`Error: ${error}`}
                      </div>
                    )}
                    {/* {JSON.stringify(rVal)} */}
                  </ListItem>
                );
              })}
            </ListPlaceholder>
          </Right>
        )}
      </InnerWrapper>

      {proxies.length == 0 ? null : (
        <BottomPlaceholder>
          <GreenButton
            onClick={async () => {
              if (checking == true) {
                return;
              }
              setChecked(false);
              setChecking(true);
              const pld = await ProxiesAPI.checkProxies(proxies);
              setCheckerResult(pld);
              setChecked(true);
              setChecking(false);
              toast('Finished');
            }}
          >
            <Spin visible={checking} />
            {checking == true ? null : <span>Check</span>}
          </GreenButton>
        </BottomPlaceholder>
      )}

      {proxies.length == 0 ||
      checkerResult == undefined ||
      checkerResult.length == 0 ? null : (
        <ExportPlaceholder>
          <DownLink
            onClick={() => {
              exportProxies(
                proxies.filter((x) => x.online),
                `online_${moment().format('DD.MM.YYYY-HH-MM-SS')}.txt`,
              );
            }}
          >
            Download <span style={{ color: 'green' }}>online</span> proxies
          </DownLink>
          <DownLink
            onClick={() => {
              exportProxies(
                proxies.filter((x) => !x.online),
                `offline_${moment().format('DD.MM.YYYY-HH-MM-SS')}.txt`,
              );
            }}
          >
            Download <span style={{ color: 'red' }}>offline</span> proxies
          </DownLink>
        </ExportPlaceholder>
      )}

      {/* <pre> */}
      {/*    {JSON.stringify(checkerResult, null, 4)} */}
      {/* </pre> */}
    </Wrapper>
  );
}

const ExportPlaceholder = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DownLink = styled.div`
  cursor: pointer;
  margin-right: 20px;
  text-decoration: underline;
  :hover {
    opacity: 0.75;
  }
`;

const InfoItem = styled.div`
  margin: 3px;
  border-radius: 4px;
  border: 1px dashed whitesmoke;
  padding: 5px;
`;

const InfoLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  opacity: 0.5;
`;

const InfoValue = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const InfosPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ListItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  background: ${(props) =>
    props.selected == true
      ? 'blue'
      : props.checked == false
        ? 'white'
        : props.online
          ? '#90ee9036'
          : 'coral'};
  color: ${(props) => (props.selected == true ? 'white' : 'black')};
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const ListPlaceholder = styled.div``;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  height: calc(90vh - 100px);
  max-height: calc(90vh - 100px);
  overflow-y: hidden;
  background: whitesmoke;
  padding: 10px;
`;

const BottomPlaceholder = styled.div`
  padding: 20px;
  border-top: 1px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Left = styled.div`
  flex: 1;
  height: 100%;
  box-sizing: border-box;
`;

const Right = styled.div`
  flex: 1;
  padding-left: 10px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;
