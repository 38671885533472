import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import styled from 'styled-components';
import UserTemplate from '../templates/UserTemplate';
import ProxiesPanel from '../proxies/panels/ProxiesPanel';
import CheckerTool from '../proxies/tools/CheckerTool';

export default function CheckerApp(props) {
  return (
    <UserTemplate active="checker">
      <Wrapper>
        <CheckerTool />
      </Wrapper>
    </UserTemplate>
  );
}

const Wrapper = styled.div`
  padding: 10px;
`;
