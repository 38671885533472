import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin';

const voidCheckbox = require('../../../assets/img/void_checkbox.svg');
const check = require('../../../assets/img/chbx.svg');

const voidRadio = require('../../../assets/img/void_radio.svg');
const checkedRadio = require('../../../assets/img/checked_radio.svg');

export default function IraSignupForm(props) {
  const {
    onLoginClick = () => {},
    canSelectRole = true,
    loading = false,
    onSubmit = (d) => {},
  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [userRole, setUserRole] = useState('student');

  return (
    <Wrapper>
      <Heading>Вход</Heading>

      {canSelectRole == false ? null : (
        <RolesPlaceholder>
          <RadioItem
            onClick={() => {
              setUserRole('teacher');
            }}
          >
            <RadioImg src={userRole == 'teacher' ? checkedRadio : voidRadio} />
            <RadioSpan>Тренер</RadioSpan>
          </RadioItem>
          <RadioItem
            onClick={() => {
              setUserRole('student');
            }}
          >
            <RadioImg src={userRole == 'student' ? checkedRadio : voidRadio} />
            <RadioSpan>Ученик</RadioSpan>
          </RadioItem>
        </RolesPlaceholder>
      )}

      <Field>
        <Label>Email</Label>
        <Input
          placeholder="Введите вашу почту"
          value={email}
          onChange={(evt) => {
            setEmail(evt.target.value);
          }}
        />
      </Field>

      <Field>
        <Label>
          <span>Пароль</span>
        </Label>
        <Input
          type="password"
          placeholder=""
          value={password}
          onChange={(evt) => {
            setPassword(evt.target.value);
          }}
        />
      </Field>

      {/* <AgreementPlaceholder> */}
      {/* <CheckboxImg src={agreed ? check : voidCheckbox} onClick={() => { */}
      {/* setAgreed(!agreed); */}
      {/* }}/> */}
      {/* <AgreeSpan> */}
      {/* Я принимаю условия соглашения */}
      {/* </AgreeSpan> */}
      {/* </AgreementPlaceholder> */}

      <ButtonPlaceholder>
        <LoginButton
          onClick={() => {
            // if (agreed == false) {
            //     return;
            // }
            onSubmit({
              email: email.toLowerCase().replace(/ /g, ''),
              password,
              userRole,
            });
          }}
        >
          <Spin visible={loading} theme="alfa-on-color" />
          {loading == true ? null : <span>SIGN UP</span>}
        </LoginButton>
      </ButtonPlaceholder>

      <VeryBottomPlaceholder>
        <BotSpan>У вас есть аккаунт?</BotSpan>
        <SignupSpan
          onClick={() => {
            onLoginClick();
          }}
        >
          <span>Войти</span>
        </SignupSpan>
      </VeryBottomPlaceholder>

      <AccPlaceholder>
        Принимаю
        <AccLink
          target="_blank"
          style={{ marginRight: 5, marginLeft: 5 }}
          href="/conf.pdf"
        >
          Политику обработки персональных данных
        </AccLink>
        и выражаю согласие на обработку персональных данных. Подтверждаю, что
        ознакомлен с условиями
        <AccLink target="_blank" style={{ marginLeft: 5 }} href="/oferta.pdf">
          Договора-оферты
        </AccLink>
        .
      </AccPlaceholder>
    </Wrapper>
  );
}

const AccPlaceholder = styled.div`
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
  text-align: center;
  padding: 5px;
  opacity: 0.6;
  font-size: 12px;
  margin-top: 12px;
`;

const AccLink = styled.a`
  margin-left: 5px;
`;

const RolesPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
`;

const RadioItem = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const RadioImg = styled.img`
  margin-right: 12px;
  height: 20px;
  width: 20px;
`;

const RadioSpan = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0444444px;
  color: #333333;
`;

const AgreementPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CheckboxImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 17px;
`;

const AgreeSpan = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #939598;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  width: 430px;
  background: white;
  box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
  border-radius: 4px;
  padding: 30px;
`;

const Heading = styled.div`
  text-align: center;
  font-weight: bold;
  color: #202224;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.114286px;
  margin-bottom: 20px;
`;

const SubHeading = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.0642857px;
  color: #202224;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 35px;
`;

const Field = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #8593a8;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

const Input = styled.input`
  font-size: 14px;
  border: 1px solid #8593a8;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;
  width: 100%;
  height: 44px;
  line-height: 42px;
  color: black;
  outline: none;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: rgba(31, 32, 65, 0.25);
  }
`;

const ButtonPlaceholder = styled.div`
  margin-top: 15px;
`;

const LoginButton = styled.div`
  width: 100%;
  cursor: pointer;
  background: #085bff;
  border-radius: 1000px;
  box-sizing: border-box;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;

  :hover {
    opacity: 0.9;
  }
`;

const ForgotSpan = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #085bff;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

const VeryBottomPlaceholder = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const BotSpan = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #202224;
  mix-blend-mode: normal;
  opacity: 0.65;
  margin-right: 10px;
`;

const SignupSpan = styled.span`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  text-decoration-line: underline;
  color: #ff624f;
  cursor: pointer;
  font-weight: 600;

  :hover {
    opacity: 0.9;
  }
`;
