import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import moment from 'moment';
import uuid from 'uuid';
import { Map, Set } from 'immutable';
import styled from 'styled-components';
import { Code } from 'react-content-loader';
import Sidebar from 'arui-feather/sidebar';
import Spin from 'arui-feather/spin';
import axios from 'axios';

import white_cam_ic from '../../../assets/img/white_cam_ic.svg';

import clock_im from '../../../assets/img/clock_im.svg';
import home_im from '../../../assets/img/home_im.svg';
import man_im from '../../../assets/img/man_im.svg';
import CommonHelper from '../../../helpers/CommonHelper';

export default function PigsMasterItemBox(props) {
  const {
    label,
    topColor,
    timeLabel,
    locationLabel,
    taskLabel,
    staffLabel,
    videoCode,
    onViewVideoClick = () => {},
    status,
    number,
    onStatusChange = (newStatus) => {},
  } = props;

  return (
    <Wrapper status={status}>
      <TopPlaceholder color={topColor}>
        <TopLeftSpan>{`№ ${number} ${label}`}</TopLeftSpan>
        <RiFliex>
          <ItemImg style={{}} src={clock_im} onClick={() => {}} />
          {timeLabel}
        </RiFliex>
      </TopPlaceholder>
      <ContentPlaceholder>
        <TaskPlaceholder>{taskLabel}</TaskPlaceholder>
        <BottomPlaceholder
          status={status}
          onClick={() => {
            if (status == 'new') {
              onStatusChange('in_progress');
            }
            if (status == 'in_progress') {
              onStatusChange('finished');
            }
          }}
        >
          {status == 'in_progress'
            ? 'Проблема решена'
            : status == 'finished'
              ? 'Задача завершена, скрыть'
              : 'Взять в работу'}
        </BottomPlaceholder>
      </ContentPlaceholder>
    </Wrapper>
  );
}

const ItemImg = styled.img`
  height: 18px;
  margin-right: 5px;
`;

const RiFliex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #a5a5a5;
`;

const ItSpan = styled.span`
  font-size: 14px;
  opacity: 0.8;
`;

const ItemSpan = styled.span`
  margin: 5px;
  display: inline-flex;
`;

const BottomPlaceholder = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 68px;
  box-sizing: border-box;

  background: ${(props) =>
    props.status == 'in_progress' ? '#00B69B' : 'white'};
  color: ${(props) => (props.status == 'in_progress' ? 'white' : '#00B69B')};
  cursor: pointer;

  font-weight: bold;
`;

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  box-sizing: border-box;
  background: ${(props) =>
    props.status == 'in_progress' ? '#2D2D2D' : 'white'};
  color: ${(props) => (props.status == 'in_progress' ? 'white' : '#2D2D2D')};
`;

const TaskPlaceholder = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid #a5a5a5;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
`;

const ContentPlaceholder = styled.div`
  padding-top: 18px;
  box-sizing: border-box;
`;

const TopRightIcon = styled.img`
  height: 32px;
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const TopLeftSpan = styled.div`
  font-size: 24px;
`;

const TopPlaceholder = styled.div`
  padding: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${(props) => props.color};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
