import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import styled from 'styled-components';
import ls from 'local-storage';

const LANGS = [
  {
    label: 'RU',
    value: 'ru',
  },
  {
    label: 'EN',
    value: 'en',
  },
  {
    label: 'FR',
    value: 'fr',
  },
  {
    label: 'CN',
    value: 'cn',
  },
];

export default function LangSwitcher(props) {
  const lang = ls('lang') || 'ru';

  return (
    <Wrapper>
      {LANGS.map((a, i) => (
        <LangItem
          selected={lang == a.value}
          key={a.value}
          onClick={() => {
            ls('lang', a.value);
            window.location.reload();
          }}
        >
          {a.label}
        </LangItem>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LangItem = styled.div`
  margin-right: 10px;
  font-weight: ${(props) => (props.selected == true ? 'bold' : 'normal')};
  cursor: ${(props) => (props.selected == true ? 'default' : 'pointer')};
`;
