/**
 * Created by sabir on 19.02.17.
 */

// users
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const INITIALIZE_AUTH_SUCCESS = 'INITIALIZE_AUTH_SUCCESS';
export const INITIALIZE_AUTH_FAIL = 'INITIALIZE_AUTH_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

// proxies

export const CREATE_PROXY = 'CREATE_PROXY';
export const CREATE_PROXY_SUCCESS = 'CREATE_PROXY_SUCCESS';
export const CREATE_PROXY_FAIL = 'CREATE_PROXY_FAIL';

export const UPDATE_PROXY = 'UPDATE_PROXY';
export const UPDATE_PROXY_SUCCESS = 'UPDATE_PROXY_SUCCESS';
export const UPDATE_PROXY_FAIL = 'UPDATE_PROXY_FAIL';

export const DELETE_PROXY = 'DELETE_PROXY';
export const DELETE_PROXY_SUCCESS = 'DELETE_PROXY_SUCCESS';
export const DELETE_PROXY_FAIL = 'DELETE_PROXY_FAIL';

export const LOAD_PROXIES = 'LOAD_PROXIES';
export const LOAD_PROXIES_SUCCESS = 'LOAD_PROXIES_SUCCESS';
export const LOAD_PROXIES_FAIL = 'LOAD_PROXIES_FAIL';

// lang
export const SET_LANGUAGE = 'SET_LANGUAGE';
